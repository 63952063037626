import type { PropsWithChildren } from 'react';
import { cn } from 'utils';

type BadgeProps = {
    type?: 'default' | 'secondary' | 'success' | 'gray';
};

export const Badge = (props: PropsWithChildren<BadgeProps>) => {
    return (
        <span
            className={cn(
                'inline-flex items-center tg-tiny-bold rounded-full h-5 min-w-7 px-2',
                {
                    default: 'bg-violet-800 text-white',
                    secondary: 'bg-violet-100 text-violet-800',
                    success: 'bg-green-100 text-green-700',
                    gray: 'bg-gray-100 text-gray-800',
                }[props.type ?? 'default'],
            )}
        >
            {props.children}
        </span>
    );
};
