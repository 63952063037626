import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStatisticsData } from 'hooks/statistics/useStatisticsData';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StatisticPageFilters from './StatisticPageFilters';
import StatisticsBillingDetail from './StatisticsBillingDetail';
import { CheckBox } from 'components/ui/checkbox';
import { RevenueForecastChart } from './RevenueForecastChart';
import {
    invoiceStatuses,
    REVENUE_FORECAST_BAR_BORDER_COLORS,
    REVENUE_FORECAST_BAR_COLORS,
} from 'constants/statisticConstants';
import { RevenueForecastInvoiceList } from './RevenueForecastInvoiceList';
import { DialogContent, DialogFooter, DialogHeader, DialogTitle, RootDialog } from 'components/ui/dialog';
import {
    DrawerClose,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerRoot,
    DrawerTitle,
} from 'components/ui/drawer';
import { Button } from 'components/common/Button';
import { IconButton } from 'components/common/IconButton';
import { DialogClose } from '@radix-ui/react-dialog';

export const RevenueForecastTab = () => {
    const { t } = useTranslation();
    const [withVat, setWithVat] = useState(false);
    // The string corresponds to the chart x-axis label
    const [selectedBar, setSelectedBar] = useState<string | null>(null);

    const [invoiceListOpen, setInvoiceListOpen] = useState(false);

    const useMobileStatisticList = window.innerWidth < 768;
    const useTabletStatisticList = !useMobileStatisticList && window.innerWidth < 1280;

    const {
        mainGraphData,
        visibleLabelText,
        dateFilter,
        onChangeFilterDateRange,
        minDatePicker,
        loading,
        invoices,
        groupByMonth,
    } = useStatisticsData({
        withVat,
        forRevenueForecast: true,
    });

    const invoiceStatusSumData = useMemo(
        () =>
            invoiceStatuses
                .map((status) => ({
                    status,
                    name: t(`statistic.invoice-status.${status}`),
                    color: REVENUE_FORECAST_BAR_COLORS[status],
                    ballBorderColor: REVENUE_FORECAST_BAR_BORDER_COLORS[status],
                    value: mainGraphData.items.reduce((acc, i) => acc + (i.values[status] ?? 0), 0),
                }))
                .filter((data) => data.value > 0),
        [mainGraphData],
    );

    const handleBarSelect = (value: string | null) => {
        setSelectedBar(value);
        if (useTabletStatisticList || useMobileStatisticList) setInvoiceListOpen(true);
    };

    const invoiceListProps = {
        withVat,
        selectedBar,
        mainGraphData,
        invoices,
        groupByMonth,
    };

    // This is in different place in mobile view, so we should have it here.
    const vatCheckBox = (
        <CheckBox
            checked={withVat}
            label={t('statistic.stats-include-vat')}
            onCheckedChange={(checked) => setWithVat(!!checked.valueOf())}
        />
    );

    if (loading) {
        return (
            <div className="flex flex-col justify-center items-center gap-4 mt-8">
                <span>{t('statistic.loading-statistics')}</span>
                <FontAwesomeIcon
                    icon={['fas', 'spinner-third']}
                    className="animate-spin size-8 text-violet-600"
                />
            </div>
        );
    }
    return (
        <div className="flex gap-12 flex-col items-stretch">
            <div className="flex flex-col gap-8">
                <StatisticPageFilters
                    dateFilter={dateFilter}
                    onChangeDateFilter={onChangeFilterDateRange}
                    minDate={minDatePicker}
                    showClientFilter={false}
                    allowFuture={true}
                />
                <div className="block xl:hidden">{vatCheckBox}</div>
            </div>

            <div className="flex flex-col xl:flex-row md:rounded-2xl md:border md:bg-white">
                <div className="flex-1 xl:border-r md:p-11 md:pt-8">
                    <div className="md:flex md:justify-between mb-14">
                        <div className="flex flex-col md:flex-row gap-7 md:gap-11">
                            <div className="flex gap-9 flex-col md:flex-row">
                                <StatisticsBillingDetail
                                    className="justify-center md:justify-start"
                                    data={{
                                        ...mainGraphData,
                                        value: mainGraphData.items.reduce(
                                            (acc, i) => acc + (i.values.total ?? 0),
                                            0,
                                        ),
                                    }}
                                    subtext={visibleLabelText}
                                    isMainData
                                    hideBall
                                    includeBackgroundOnMobile
                                />

                                <div className="w-px min-w-px hidden xl:block bg-gray-200" />
                                <div className="flex gap-6 md:gap-9 overflow-x-auto">
                                    {invoiceStatusSumData.map((data, index) => (
                                        <StatisticsBillingDetail
                                            className="flex-none"
                                            key={index}
                                            data={data}
                                            large
                                            textClassName={data.status === 'overdued' ? 'text-red-900' : ''}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="hidden xl:block mt-2">{vatCheckBox}</div>
                    </div>
                    <div className="bg-gray-50 md:bg-inherit md:w-auto md:mx-0 border-y md:border-y-0">
                        <RevenueForecastChart
                            graphData={mainGraphData}
                            selectedBar={selectedBar}
                            onBarSelect={handleBarSelect}
                        />
                    </div>
                </div>
                <div className="hidden xl:block">
                    <RevenueForecastInvoiceList {...invoiceListProps} />
                </div>
                <RootDialog
                    open={invoiceListOpen && useTabletStatisticList}
                    onOpenChange={setInvoiceListOpen}
                >
                    <DialogContent className="w-[500px] p-0" aria-describedby={undefined}>
                        <DialogHeader className="pl-6 flex justify-between flex-row items-center">
                            <DialogTitle>{t('statistic.due-invoices')}</DialogTitle>

                            <DialogClose asChild>
                                <IconButton icon={['far', 'xmark']} />
                            </DialogClose>
                        </DialogHeader>
                        <RevenueForecastInvoiceList {...invoiceListProps} />
                        <DialogFooter>
                            <Button onClick={() => setInvoiceListOpen(false)} variant="outline">
                                Sulje
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </RootDialog>

                <DrawerRoot
                    open={invoiceListOpen && useMobileStatisticList}
                    onOpenChange={setInvoiceListOpen}
                >
                    <DrawerContent>
                        <DrawerHeader className="flex justify-between items-center h-14">
                            <DrawerTitle className="tg-subtitle">{t('statistic.due-invoices')}</DrawerTitle>
                            <DrawerClose asChild>
                                <IconButton icon={['far', 'chevron-down']} />
                            </DrawerClose>
                        </DrawerHeader>
                        <RevenueForecastInvoiceList {...invoiceListProps} />
                        <DrawerFooter className="p-6">
                            <Button onClick={() => setInvoiceListOpen(false)} variant="outline">
                                Sulje
                            </Button>
                        </DrawerFooter>
                    </DrawerContent>
                </DrawerRoot>
            </div>
        </div>
    );
};
