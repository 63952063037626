import { useTranslation } from 'react-i18next';
import NestedPageNavigationTabs from 'components/layout/NestedPageNavigationTabs';

import { StatisticsTab } from 'components/statistics/StatisticsTab';
import { RevenueForecastTab } from 'components/statistics/RevenueForecastTab';
import BackButtonWithTitle from 'components/BackButtonWithTitle';

export enum SalesTabEnum {
    Billing = 'billing',
    EezyPay = 'eezypay',
    RevenueForecast = 'revenueForecast',
}

const StatisticsPage = () => {
    const { t } = useTranslation();

    const statisticsTabs = [
        {
            label: t('statistic.tabs.invoices'),
            value: SalesTabEnum.Billing,
            component: <StatisticsTab activeSalesTab={SalesTabEnum.Billing} />,
        },
        {
            label: t('statistic.tabs.eezy-page'),
            value: SalesTabEnum.EezyPay,
            component: <StatisticsTab activeSalesTab={SalesTabEnum.EezyPay} />,
        },
        {
            label: t('statistic.tabs.revenue-forecast'),
            value: SalesTabEnum.RevenueForecast,
            component: <RevenueForecastTab />,
            info: { badge: t('eezyPay:menu-item-badge-new') },
        },
    ];

    return (
        <div className="bg-white md:bg-gray-50 px-6 md:px-14 py-10">
            <div className="mb-12">
                <BackButtonWithTitle />
                <div className="mt-4 md:mt-5 tg-heading-2">{t('statistic.page-title')}</div>
            </div>

            <NestedPageNavigationTabs tabs={statisticsTabs} urlParameter="subPage" urlPrefix="/statistics" />
        </div>
    );
};

export default StatisticsPage;
