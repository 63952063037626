import { useTranslation } from 'react-i18next';
import { formatCents } from 'utils';
import { Icon } from 'components/Icon';
import { useMemo } from 'react';
import { trpc } from 'trpc';

const PendingPaymentInvoice = () => {
    const { t } = useTranslation();

    const { data } = trpc.invoices.list.useQuery();

    const incomeOpen = useMemo(() => {
        const invoices = data?.items ?? [];

        const openInvoices = invoices.filter(
            (invoice) =>
                ['invoiced', 'completed', 'overdued', 'unaccepted'].includes(invoice.status ?? '') &&
                !invoice.paymentReceived,
        );

        return openInvoices.reduce((acc, invoice) => acc + invoice.total, 0);
    }, [data]);

    return (
        <div className="py-6 flex gap-2.5 items-center" style={{ color: '#351F65' }}>
            <Icon icon={['far', 'clock']} />
            <div>{t('dashboard.invoices.pending-payment')}:</div>
            <b className="text-xl">{formatCents(incomeOpen, true)} €</b>
        </div>
    );
};

export default PendingPaymentInvoice;
