interface IClientConfig {
    backendUrl: string;
    backendUrl2: string;
    backendUrl3: string;
    newApiUrl: string;
    env: string;
    giosgInteraction1MinutesTillRepeat: number;
    giosgInteraction2MinutesTillRepeat: number;
    giosgInteraction2TimesToRepeat: number;
    googleMapsKey: string; // Maps, JavaScript API, Directions API, Geocoding API
    hasuraUrl: string;
    subscriptionUrl: string;
    eezyPayPublicPath: string;
    eezyPayApiHost: string;
    eezyPaySubscriptionPayUrl1En: string;
    eezyPaySubscriptionPayUrl2En: string;
    eezyPaySubscriptionPayUrl3En: string;
    eezyPaySubscriptionPayUrl1Fi: string;
    eezyPaySubscriptionPayUrl2Fi: string;
    eezyPaySubscriptionPayUrl3Fi: string;
    eezyPaySubscriptionPayUrl4Fi: string;
    eezyPaySubscriptionPayUrl4En: string;
    campwireSubscriptionUrl1En: string;
    campwireSubscriptionUrl3En: string;
    campwireSubscriptionUrl12En: string;
    campwireSubscriptionUrl1Fi: string;
    campwireSubscriptionUrl3Fi: string;
    campwireSubscriptionUrl12Fi: string;
}

const envStr =
    process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
        ? 'dev'
        : (process.env.REACT_APP_RUNTIME_ENV as string);

const { hostname, port, protocol } = window.location;
const serverUrl = `${protocol}//${hostname}`;
const devBackend = `${protocol}//${hostname}:${port}`; // Use dev proxy

let config: IClientConfig;

if (envStr === 'prod') {
    config = {
        backendUrl: `${serverUrl}/v1/`,
        backendUrl2: `${serverUrl}/v2/graphql/`,
        backendUrl3: `${serverUrl}/v2/binary`,
        newApiUrl: `${serverUrl}/v3`,
        env: 'prod',
        giosgInteraction1MinutesTillRepeat: 336 * 60,
        giosgInteraction2MinutesTillRepeat: 12 * 60,
        giosgInteraction2TimesToRepeat: 2,
        googleMapsKey: 'AIzaSyB1YQ8EVjzb18QZOS6yarOt5OydoYoU_QI',
        hasuraUrl: 'https://hasura.eezypay.fi/v1/graphql',
        subscriptionUrl: `wss://${hostname}/v2/subs`,
        eezyPayPublicPath: 'https://pay.eezypay.fi/pay',
        eezyPayApiHost: 'https://api.eezypay.fi/v1',
        eezyPaySubscriptionPayUrl1En: 'https://pay.eezypay.fi/pay/5s5k1e6v4cl',
        eezyPaySubscriptionPayUrl2En: 'https://pay.eezypay.fi/pay/8gqjskjlnf',
        eezyPaySubscriptionPayUrl3En: 'https://pay.eezypay.fi/pay/burlwr2ggj',
        eezyPaySubscriptionPayUrl1Fi: 'https://pay.eezypay.fi/pay/5k8hwijnwy',
        eezyPaySubscriptionPayUrl2Fi: 'https://pay.eezypay.fi/pay/4saa5gjuxt',
        eezyPaySubscriptionPayUrl3Fi: 'https://pay.eezypay.fi/pay/4rbf5jq3cs',
        eezyPaySubscriptionPayUrl4Fi: 'https://pay.eezypay.fi/pay/vckmxt8uwc',
        eezyPaySubscriptionPayUrl4En: 'https://pay.eezypay.fi/pay/y3h2a5hrfe',
        campwireSubscriptionUrl1En: 'https://pay.eezypay.fi/pay/wtsdmbktlf',
        campwireSubscriptionUrl3En: 'https://pay.eezypay.fi/pay/q2eswdag1v',
        campwireSubscriptionUrl12En: 'https://pay.eezypay.fi/pay/n5pt0atytdj',
        campwireSubscriptionUrl1Fi: 'https://pay.eezypay.fi/pay/1nphn1htqu',
        campwireSubscriptionUrl3Fi: 'https://pay.eezypay.fi/pay/6uf9sukydpf',
        campwireSubscriptionUrl12Fi: 'https://pay.eezypay.fi/pay/uwvsiwt9zc',
    };
} else if (envStr === 'qa') {
    config = {
        backendUrl: `${serverUrl}/v1/`,
        backendUrl2: `${serverUrl}/v2/graphql/`,
        backendUrl3: `${serverUrl}/v2/binary`,
        newApiUrl: `${serverUrl}/v3`,
        env: 'qa',
        giosgInteraction1MinutesTillRepeat: 15,
        giosgInteraction2MinutesTillRepeat: 10,
        giosgInteraction2TimesToRepeat: 2,
        googleMapsKey: 'AIzaSyB1YQ8EVjzb18QZOS6yarOt5OydoYoU_QI',
        hasuraUrl: 'https://hasura-test.eezypay.fi/v1/graphql',
        subscriptionUrl: `wss://${hostname}/v2/subs`,
        eezyPayPublicPath: 'https://test.eezypay.fi/pay',
        eezyPayApiHost: 'https://api-test.eezypay.fi/v1',
        eezyPaySubscriptionPayUrl1En: 'https://test.eezypay.fi/pay/3y7it9zb5e',
        eezyPaySubscriptionPayUrl2En: 'https://test.eezypay.fi/pay/2fbh86c6qv',
        eezyPaySubscriptionPayUrl3En: 'https://test.eezypay.fi/pay/1zhl2inmuq',
        eezyPaySubscriptionPayUrl1Fi: 'https://test.eezypay.fi/pay/0ct80scvs8',
        eezyPaySubscriptionPayUrl2Fi: 'https://test.eezypay.fi/pay/0caxhxnhvd',
        eezyPaySubscriptionPayUrl3Fi: 'https://test.eezypay.fi/pay/7oqnhi1a3o',
        eezyPaySubscriptionPayUrl4Fi: 'https://test.eezypay.fi/pay/gu11u4n7i6',
        eezyPaySubscriptionPayUrl4En: 'https://test.eezypay.fi/pay/ab0xystwm6',
        campwireSubscriptionUrl1En: 'https://test.eezypay.fi/pay/ywdo02ym4d',
        campwireSubscriptionUrl3En: 'https://test.eezypay.fi/pay/vcptpq0n0a',
        campwireSubscriptionUrl12En: 'https://test.eezypay.fi/pay/yr30g239u5',
        campwireSubscriptionUrl1Fi: 'https://test.eezypay.fi/pay/pkpgyazwf',
        campwireSubscriptionUrl3Fi: 'https://test.eezypay.fi/pay/yrzmcnwvbo',
        campwireSubscriptionUrl12Fi: 'https://test.eezypay.fi/pay/8h074tgqet',
    };
} else if (envStr === 'dev') {
    config = {
        backendUrl: `${devBackend}/v1`,
        backendUrl2: `${devBackend}/v2/graphql`,
        backendUrl3: `${devBackend}/v2/binary`,
        newApiUrl: 'http://localhost:3333/v3',
        env: 'dev',
        giosgInteraction1MinutesTillRepeat: 15,
        giosgInteraction2MinutesTillRepeat: 5,
        giosgInteraction2TimesToRepeat: 2,
        googleMapsKey: 'AIzaSyB1YQ8EVjzb18QZOS6yarOt5OydoYoU_QI',
        hasuraUrl: `${devBackend}/v1/graphql`,
        subscriptionUrl: 'ws://localhost:5000/v2/subs',
        eezyPayPublicPath: 'http://localhost:3001/pay',
        eezyPayApiHost: 'http://localhost:8000/v1',
        eezyPaySubscriptionPayUrl1En: 'https://test.eezypay.fi/pay/3y7it9zb5e',
        eezyPaySubscriptionPayUrl2En: 'https://test.eezypay.fi/pay/2fbh86c6qv',
        eezyPaySubscriptionPayUrl3En: 'https://test.eezypay.fi/pay/1zhl2inmuq',
        eezyPaySubscriptionPayUrl1Fi: 'https://test.eezypay.fi/pay/0ct80scvs8',
        eezyPaySubscriptionPayUrl2Fi: 'https://test.eezypay.fi/pay/0caxhxnhvd',
        eezyPaySubscriptionPayUrl3Fi: 'https://test.eezypay.fi/pay/7oqnhi1a3o',
        eezyPaySubscriptionPayUrl4Fi: 'https://test.eezypay.fi/pay/gu11u4n7i6',
        eezyPaySubscriptionPayUrl4En: 'https://test.eezypay.fi/pay/ab0xystwm6',
        campwireSubscriptionUrl1En: 'https://test.eezypay.fi/pay/ywdo02ym4d',
        campwireSubscriptionUrl3En: 'https://test.eezypay.fi/pay/vcptpq0n0a',
        campwireSubscriptionUrl12En: 'https://test.eezypay.fi/pay/yr30g239u5',
        campwireSubscriptionUrl1Fi: 'https://test.eezypay.fi/pay/pkpgyazwf',
        campwireSubscriptionUrl3Fi: 'https://test.eezypay.fi/pay/yrzmcnwvbo',
        campwireSubscriptionUrl12Fi: 'https://test.eezypay.fi/pay/8h074tgqet',
    };
} else {
    throw new Error(`Cannot find config for envStr ${envStr}`);
}

export default config;
