export const SalaryCalcConfig = {
    dailyAllowance: 53.0,
    debug: false,
    eezyServiceRate: 7,
    halfDailyAllowance: 24.0,
    healthInsuranceRate: 1.87,
    mealReimbursement: 13.25,
    mileageReimbursement: 0.59,
    mileageReimbursementTrailer: 0.1,
    vatRate: 24,
    yelRate: 24.1,
    eezyPayServiceRate: 1.5,
};
