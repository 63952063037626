import { Tooltip as MuiTooltip } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import {
    COLOR_BLACKWATER,
    COLOR_GREYS_ANATOMY,
    COLOR_IMPORTANT,
    COLOR_JAFFA,
    COLOR_WHITE_WALKER,
} from '../styles/variables';

interface IInfoTooltipProps {
    backgroundColor?: string;
    textColor?: string;
    textAlign?: 'center' | 'left' | 'right';
}

export const ErrorTooltip = withStyles(MuiTooltip, {
    arrow: {
        color: COLOR_IMPORTANT,
        marginRight: 10,
    },
    tooltip: {
        backgroundColor: COLOR_IMPORTANT,
        color: COLOR_WHITE_WALKER,
        fontSize: 12,
        padding: 10,
        textAlign: 'center',
    },
});

export const WarningTooltip = withStyles(MuiTooltip, {
    arrow: {
        color: '#F6B700',
        marginRight: 10,
    },
    tooltip: {
        background: COLOR_JAFFA,
        color: COLOR_BLACKWATER,
        fontSize: 12,
        padding: 10,
        textAlign: 'center',
    },
});

// @ts-expect-error - Old code, We have a new tooltip component, so this file will be deleted once migration is done
export const InfoTooltip = withStyles(MuiTooltip, () => ({
    arrow: {
        color: (props: IInfoTooltipProps) =>
            props.backgroundColor ? props.backgroundColor : COLOR_GREYS_ANATOMY,
        marginRight: 10,
    },
    tooltip: {
        background: (props: IInfoTooltipProps) =>
            props.backgroundColor ? props.backgroundColor : COLOR_GREYS_ANATOMY,
        color: (props: IInfoTooltipProps) => (props.textColor ? props.textColor : COLOR_BLACKWATER),
        fontSize: 12,
        padding: 10,
        textAlign: (props: IInfoTooltipProps) => props.textAlign ?? 'center',
        '& ul': {
            fontSize: 12,
            paddingLeft: 12,
            listStyle: 'disc',
        },
        '& a': {
            color: '#41f0ff'
        },
    },
}));
