import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import type { ThunkDispatch } from 'redux-thunk';
import type { INotification } from '../../../../shared/src/types/notifications';
import { EezyButton } from 'components/Buttons';
import { HeaderRow } from 'components/HeaderRow';
import { UniqueTitle } from 'components/textElements';
import { subtractTwoWeeks } from 'utils';
import { getLinkHref } from 'utils/notifications/notifications';
import { useUser } from 'queries/useUserQuery';
import LoadingSpinner from 'components/Loading';
import NotificationsItem from './NotificationsItem';
import NotificationModal from './NotificationModal';
import LIST_NOTIFICATIONS from './queries/listNotifications';
import SET_HIDE_NOTIFICATION from './queries/setHideNotification';
import SET_READ_NOTIFICATION from './queries/setReadNotification';

type NotificationsData = {
    getNotifications: INotification[];
};

const Notifications = () => {
    const user = useUser();

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();

    const [visibleNotification, setVisibleNotification] = useState<INotification | undefined>(undefined);
    const [showArchive, setShowArchive] = useState(false);
    const [notifications, setNotifications] = useState<INotification[]>([]);

    const el = useRef<HTMLDivElement>(null);

    const { data: notificationsData, loading } = useQuery<NotificationsData>(LIST_NOTIFICATIONS, {
        errorPolicy: 'all',
        fetchPolicy: 'cache-and-network',
        variables: {
            dateLimitForHiding: subtractTwoWeeks(user?.registrationDate ?? ''),
            lang: i18n.language,
            showHidden: showArchive,
            registrationDate: user?.registrationDate,
        },
        skip: !user,
    });

    const [hideNotification] = useMutation(SET_HIDE_NOTIFICATION, {
        refetchQueries: [LIST_NOTIFICATIONS],
    });

    const [readNotification] = useMutation(SET_READ_NOTIFICATION, {
        refetchQueries: [LIST_NOTIFICATIONS],
    });

    const handleNotificationOpen = (notification: INotification) => {
        const link = getLinkHref(notification);
        if (link) {
            navigate(link);
        } else if (notification.actionType === 'expense') {
            setVisibleNotification(notification);
        } else {
            setVisibleNotification(notification);
            readNotification({
                variables: {
                    id: notification.id,
                    announcementId: notification.announcementId,
                },
            });
        }
    };

    const handleNotificationArchive = (notification: INotification) => {
        hideNotification({
            variables: {
                id: notification.id,
                announcementId: notification.announcementId,
            },
        });
    };

    useEffect(() => {
        if (notificationsData?.getNotifications?.length) {
            setNotifications(notificationsData.getNotifications);
        }
    }, [notificationsData?.getNotifications]);

    useEffect(() => {
        const { pathname } = location;
        if (['/notifications'].includes(pathname)) {
            window.scrollTo(0, document.body.scrollHeight);
        }
    }, []);

    return (
        <div ref={el} className="flex flex-col mb-9 w-full bg-white rounded-eezy px-5 py-7 relative">
            <HeaderRow>
                <UniqueTitle>{t('dashboard.messages.title')}</UniqueTitle>

                <EezyButton color="purple" dark onClick={() => setShowArchive(!showArchive)} width={150}>
                    {showArchive ? t('dashboard.only-new') : t('dashboard.only-archive')}
                </EezyButton>
            </HeaderRow>

            {notifications.map((item: INotification) => (
                <NotificationsItem
                    key={item.id || item.announcementId}
                    isIdentified={Boolean(user?.isIdentified)}
                    data={item}
                    onOpen={handleNotificationOpen}
                    onArchive={handleNotificationArchive}
                />
            ))}

            {loading && (
                <div className="absolute w-full h-full top-0 left-0 bg-white/50 text-center pt-10">
                    <LoadingSpinner color="#351f67" />
                </div>
            )}

            <NotificationModal
                isOpen={!!visibleNotification}
                handleModalClose={() => setVisibleNotification(undefined)}
                notification={visibleNotification}
            />
        </div>
    );
};

export default Notifications;
