import { gql } from '@apollo/client';

const GET_COST_EXPENSE_VAT_OPTIONS = gql`
    query GetCostExpenseVatOptions {
        costExpenseVatOptions {
            value
            default
        }
    }
`;

export default GET_COST_EXPENSE_VAT_OPTIONS;
