import { gql } from '@apollo/client';

const LIST_NOTIFICATIONS = gql`
    query ListNotifications($lang: LanguageId!, $dateLimitForHiding: String, $showHidden: Boolean, $registrationDate: String) {
        getNotifications(lang: $lang, dateLimitForHiding: $dateLimitForHiding, showHidden: $showHidden, registrationDate: $registrationDate) {
            actionData {
                downloadUrl
                dueDate
                name
                sum
            }
            id
            announcementId
            actionType
            content
            date
            isHidden
            isRead
            title
            type
        }
    }
`;

export default LIST_NOTIFICATIONS;
