import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApolloClient, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { trans } from '../../utils';
import { DELETE_INVOICE } from './queries';
import ConfirmationModal from '../../components/modals/ConfirmationModal';
import { trpc } from 'trpc';

interface IDeleteInvoiceProps {
    invoiceId: number;
    open: boolean;
    toggleOpen: (open: boolean) => void;
}
export const DeleteInvoice = (props: IDeleteInvoiceProps) => {
    const navigate = useNavigate();
    const client = useApolloClient();
    const trpcUtils = trpc.useUtils();

    const [deleteInvoice] = useMutation(DELETE_INVOICE);

    const handleDeleteInvoice = async () => {
        try {
            await deleteInvoice({
                variables: { invoiceId: props.invoiceId },
                notifyOnNetworkStatusChange: true,
            });
            trpcUtils.invoices.list.invalidate();
            navigate('/invoices', { replace: true });
        } catch (e) {
            console.error(e);
            toast.error(trans('errors.general'));
        }
    };

    const openDeleteInvoice = (e: any) => {
        if (e.keyCode === 46 && e.srcElement.tagName !== 'INPUT' && e.srcElement.tagName !== 'TEXTAREA') {
            props.toggleOpen(true);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', openDeleteInvoice, false);
        return () => {
            document.removeEventListener('keydown', openDeleteInvoice, false);
        };
    }, [openDeleteInvoice]);

    return (
        <ConfirmationModal
            handleSubmit={handleDeleteInvoice}
            handleClose={() => props.toggleOpen(false)}
            id={`delete-${props.invoiceId}`}
            isOpen={props.open}
            texts={{
                submit: 'form.delete',
                title: 'invoice.confirm-delete',
            }}
        />
    );
};
