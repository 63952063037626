export type VatOption = {
    value: number;
    default: boolean;
};

export enum InvoiceRowType {
    Work = 'work',
    Travel = 'travel',
    Material = 'material',
}
