import { Badge } from 'components/common/Badge';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

type NestedPageNavigationTabsProps = {
    tabs: {
        label: string;
        value: string;
        component: JSX.Element;
        info?: { badge?: string };
    }[];
    urlParameter?: string;
    urlPrefix?: string;
};

/**
 *
 * @param props urlPrefix should include the leading slash, e.g. '/statistics'
 */
const NestedPageNavigationTabs = (props: NestedPageNavigationTabsProps) => {
    const params = useParams();

    const subPageFromParams = useMemo(() => {
        const parameter = props.urlParameter;
        if (!parameter) return props.tabs[0].value;

        return props.tabs.find((tab) => tab.value === params[parameter])?.value || props.tabs[0].value;
    }, [params, props.tabs, props.urlParameter]);

    const [activeTab, setActiveTab] = useState(subPageFromParams);

    useEffect(() => {
        setActiveTab(subPageFromParams);
    }, [subPageFromParams]);

    const handleTabChange = (value: string) => {
        setActiveTab(value);
        if (props.urlPrefix) history.replaceState(null, '', `${props.urlPrefix || ''}/${value}`);
    };

    return (
        <Tabs value={activeTab} onValueChange={handleTabChange}>
            <TabsList className="mb-12">
                {props.tabs.map((tab) => (
                    <TabsTrigger key={tab.value} value={tab.value}>
                        <div className="flex gap-2">
                            {tab.label}
                            {tab.info?.badge ? <Badge>{tab.info.badge}</Badge> : null}
                        </div>
                    </TabsTrigger>
                ))}
            </TabsList>
            {props.tabs.map((tab) => (
                <TabsContent key={tab.value} value={tab.value}>
                    {tab.component}
                </TabsContent>
            ))}
        </Tabs>
    );
};

export default NestedPageNavigationTabs;
