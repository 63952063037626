import type { CSSProperties, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import styled from 'styled-components';
import { FormRow, type IGeneralFormInputProps, SplitRow } from '.';
import { COLOR_BLACKWATER, COLOR_GREY_FOG, COLOR_WHITE_WALKER, FOCUS_COLOR } from 'styles/variables';
import { InfoTooltipStyled } from '../InfoTooltipStyled';
import LoadingSpinner from '../Loading';
import { AutocompleteDropdown, type IDropdownOption } from './AutocompleteDropdown';
import { FormLabel } from './FormLabel';

const StyledField = styled(TextField).attrs(() => ({
    autoComplete: 'off',
}))`
    &&&& {
        vertical-align: baseline;
        width: 100%;

        div:first-child {
            padding-right: 0;
        }

        input {
            border-bottom: 1px solid;
            border-color: ${COLOR_GREY_FOG};
            box-sizing: border-box;
            color: ${COLOR_BLACKWATER};
            font-size: 15px;
            height: 40px;
            padding: 0 25px 0 0;
            text-align: left;
            width: 100%;

            &:focus {
                border-color: ${FOCUS_COLOR} !important;
            }
            &:disabled {
                border-bottom: none;
            }
        }
    }
`;

interface IFormSelectProps extends IGeneralFormInputProps {
    getOptionLabel?: (option: IDropdownOption) => string;
    labelStyle?: CSSProperties;
    onChange: (val: string, name: string) => void;
    options: IDropdownOption[];
    optionsLoading?: boolean;
    renderOption?: (option: IDropdownOption | HTMLAttributes<HTMLLIElement>) => string;
    selectStyle?: CSSProperties;
    showIcon?: boolean;
    splitRow?: boolean;
    value?: string;
    labelIcon?: any;
    id?: string;
    optional?: boolean;
    noWrap?: boolean;
    placeholder?: string;
    tooltip?: string;
    tooltipColor?: string;
    tooltipBackground?: string;
    tooltipTextAlign?: 'center' | 'left' | 'right';
    clearable?: boolean;
}

const BlankWrap = styled.div``;

export const FormSelect = (props: IFormSelectProps) => {
    const { t } = useTranslation();
    let Wrapper = BlankWrap;
    if (!props.noWrap) {
        Wrapper = props.splitRow ? SplitRow : FormRow;
    }
    return (
        <Wrapper>
            <FormLabel
                error={props.error}
                disabled={props.disabled}
                helpLink={props.helpLink}
                helpTexts={props.helpTexts}
                htmlFor={props.name}
                style={props.labelStyle}
                value={props.value}
                warning={props.warning}
            >
                {props.label}
                {props.optional && (
                    <span style={{ fontWeight: 400, fontStyle: 'italic' }}>{` (${t(
                        'general.optional',
                    )})`}</span>
                )}
                {props.labelIcon}
                {props.tooltip && (
                    <InfoTooltipStyled
                        tooltip={props.tooltip}
                        tooltipBackground={props.tooltipBackground}
                        tooltipColor={props.tooltipColor}
                        textAlign={props.tooltipTextAlign}
                    />
                )}
            </FormLabel>

            <AutocompleteDropdown
                clearable={props.clearable}
                error={props.error}
                disabled={props.disabled}
                getOptionLabel={props.getOptionLabel}
                id={props.id || props.name}
                name={props.name}
                options={props.options}
                onChange={(val: string | number) => {
                    if (typeof val === 'string') {
                        props.onChange(val, props.name);
                    }
                }}
                renderInput={(params) =>
                    props.optionsLoading ? (
                        <LoadingSpinner size="1em" />
                    ) : (
                        <StyledField
                            {...params}
                            required={props.required}
                            placeholder={props.placeholder}
                            inputProps={{
                                ...params.inputProps,
                                'aria-labelledby': props.name,
                                'data-lpignore': 'true', // Disable Last pass,
                                style: props.style,
                            }}
                        />
                    )
                }
                renderOption={props.renderOption}
                iconColor={`${props.color || COLOR_WHITE_WALKER}`}
                showIcon={props.showIcon && !props.disabled}
                style={props.selectStyle}
                type={'text'}
                value={props.value || ''}
            />
        </Wrapper>
    );
};
