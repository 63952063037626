import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import type { IReceiptCost, ITravel } from '../../../../shared/src/types/costs';
import type { IInvoice, InvoiceStatus } from '../../../../shared/src/types/invoice';
import { EezyButton, EezyRouterLink } from 'components/Buttons';
import { Flex } from 'components/Flex';
import { FormSection, FormTextArea } from 'components/form';
import { Icon } from 'components/Icon';
import { WhiteDocumentArea } from 'components/layout/WhiteDocumentArea';
import LoadingSpinner from 'components/Loading';
import { LabelCapsSmall, P, PSmall, TitleCapsBold } from 'components/textElements';
import { ValidationMessageBlock } from 'components/ValidationMessageBlock';
import { COLOR_BLUM, COLOR_GREY_FOG, COLOR_STATUS_WAITING } from 'styles/variables';
import { formatCents } from 'utils';
import {
    canCostInvoiceBeEditedEvenInvoiceIsPaid,
    countCostStatuses,
    filterTravelsByStatus,
    getCostInvoiceRejectedTotal,
    getCostStatus,
    getCostsTotalWithVat,
    getTravelCopy,
    getTravelsTotal,
    MATERIAL,
    TRAVEL_CAR_ROUND,
    TRAVEL_OTHER_ROUND,
    travelToTravelInput,
} from 'utils/costs/costLogic';
import {
    canCostInvoiceMessageBeEdited,
    costInvoiceHasCosts,
    isAnkkaId,
    isCostInvoiceEditable,
    isCostInvoiceReSendable,
    isCostInvoiceStatusEditable,
    isGroupInvoiceCoworker,
    isTurnedBackAndNotEditable,
    shouldCostInvoiceBeReturnedBeforeEditing,
} from 'utils/invoice/invoiceLogic';
import {
    CREATE_TRAVEL,
    DELETE_COST_INVOICE,
    GET_ALLOWANCES,
    RETURN_COST_INVOICE,
    UPDATE_COSTINVOICE,
} from './queries';
import { GET_INVOICE, RETURN_TURNED_BACK_INVOICE } from '../invoice/queries';
import { CostDocCostRow } from './CostDocCostRow';
import { CostDocTravelRow } from './CostDocTravelRow';
import CostDocumentSummary from './CostDocumentSummary';
import { CostInvoiceTable } from './CostInvoiceTable';
import { useUser } from 'queries/useUserQuery';
import { trpc } from 'trpc';

interface IProps {
    editable?: boolean;
    invoice: IInvoice;
    invoiceStatus?: InvoiceStatus;
    onCostInvoiceSend?: () => void;
    sendingLoading?: boolean;
    openModalsForSalaryAlreadyPaid?: {
        salaryPaidEarlierModalOpen: (open: boolean) => void;
        salaryPaidJustModalOpen: (open: boolean) => void;
    };
    editButtonOnInvoicePreview?: boolean;
    hasContract?: boolean;
}

const CostDocument = (props: IProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const user = useUser();
    const { editable, invoice } = props;
    const costInvoice = invoice.costInvoice;

    const editableCostInvoice =
        editable && isCostInvoiceEditable(invoice) && !shouldCostInvoiceBeReturnedBeforeEditing(invoice);
    const isMessageEditable = editable && canCostInvoiceMessageBeEdited(invoice);
    const salaryAlreadyPaid = invoice?.status === 'paid' && !canCostInvoiceBeEditedEvenInvoiceIsPaid(invoice);
    const groupInvoiceCoworker = isGroupInvoiceCoworker(user?.id, invoice);
    const invoiceBaseUrl = groupInvoiceCoworker ? '/group' : '/invoices';

    const client = useApolloClient();

    const mutationVariables = {
        onCompleted: () => {
            toast(t('general.saved'));
        },
        onError: () => {
            toast.error(t('errors.general'));
        },
        refetchQueries: () => [
            {
                query: GET_INVOICE,
                variables: {
                    id: props.invoice.id,
                    isGroupInvoice: groupInvoiceCoworker,
                },
            },
        ],
    };
    const [createTravel, { loading: createTravelLoading }] = useMutation(CREATE_TRAVEL, mutationVariables);

    const [updateCostInvoice] = useMutation(UPDATE_COSTINVOICE, mutationVariables);

    const [returnCostInvoice, { loading: returnLoading }] = useMutation(RETURN_COST_INVOICE, {
        ...mutationVariables,
        onCompleted: () => {
            if (!props.editable) {
                navigate(`${invoiceBaseUrl}/${props.invoice.id}`);
            }
        },
        variables: {
            id: costInvoice?.id,
            status: costInvoice?.status,
        },
    });

    const [returnTurnedBackInvoice] = useMutation(RETURN_TURNED_BACK_INVOICE, {
        onCompleted: ({ returnTurnedBackInvoice: newInvoice }) => {
            if (!props.editable) {
                navigate(`${invoiceBaseUrl}/${newInvoice.id}`);
            }

            trpc.useUtils().invoices.list.invalidate();
        },
        onError: () => toast.error(t('errors.general')),
        variables: { id: invoice?.id },
    });

    const [deleteCostInvoice, { loading: deletingCostInvoice }] = useMutation(DELETE_COST_INVOICE, {
        onCompleted: () => {
            toast(t('general.deleted'));
        },
        onError: () => {
            toast.error(t('errors.general'));
        },
        update(cache, { data: { deleteCostInvoice: success } }) {
            if (success && props.invoice?.id) {
                cache.writeQuery({
                    data: {
                        invoice: {
                            ...props.invoice,
                            costInvoice: null,
                        },
                    },
                    query: GET_INVOICE,
                    variables: {
                        id: props.invoice?.id,
                        isGroupInvoice: groupInvoiceCoworker,
                    },
                });
            }
        },
        variables: { costInvoiceId: props.invoice.costInvoice?.id },
    });

    const [checkSalaryPaymentStatusForInvoiceAndReturnTurnedBack] = useLazyQuery(GET_INVOICE, {
        fetchPolicy: 'network-only',
        onCompleted: (data: any) => {
            const { invoice: resInvoice } = data;
            if (
                resInvoice &&
                resInvoice?.status === 'paid' &&
                !canCostInvoiceBeEditedEvenInvoiceIsPaid(resInvoice)
            ) {
                props.openModalsForSalaryAlreadyPaid?.salaryPaidEarlierModalOpen(true);
            } else {
                returnTurnedBackInvoice();
            }
        },
    });

    const [checkSalaryPaymentStatusForInvoiceAndReturnCostInvoice] = useLazyQuery(GET_INVOICE, {
        fetchPolicy: 'network-only',
        onCompleted: (data: any) => {
            const { invoice: resInvoice } = data;
            if (
                resInvoice &&
                invoice?.status === 'paid' &&
                !canCostInvoiceBeEditedEvenInvoiceIsPaid(resInvoice)
            ) {
                props.openModalsForSalaryAlreadyPaid?.salaryPaidEarlierModalOpen(true);
            } else {
                returnCostInvoice();
            }
        },
    });

    if (!costInvoice) {
        return null;
    }

    const handleCopyTravel = () => {
        const lastTravel = costInvoice.travels.slice(-1)[0];
        if (lastTravel) {
            const travelCopy = getTravelCopy(travelToTravelInput(lastTravel));
            createTravel({
                variables: {
                    costInvoiceId: costInvoice?.id,
                    travel: travelCopy,
                },
            });
        }
    };

    const handleUpdateMessage = (messageToEezy: string) => {
        updateCostInvoice({
            variables: { costInvoiceId: costInvoice.id, messageToEezy },
        });
    };

    const handleCostInvoiceReturn = () => {
        if (invoice.status === 'paid') {
            if (isTurnedBackAndNotEditable(invoice)) {
                checkSalaryPaymentStatusForInvoiceAndReturnTurnedBack({
                    variables: {
                        id: invoice.id,
                        isGroupInvoice: groupInvoiceCoworker,
                    },
                });
            } else {
                checkSalaryPaymentStatusForInvoiceAndReturnCostInvoice({
                    variables: {
                        id: invoice.id,
                        isGroupInvoice: groupInvoiceCoworker,
                    },
                });
            }
        } else if (isTurnedBackAndNotEditable(invoice)) {
            returnTurnedBackInvoice();
        } else {
            returnCostInvoice();
        }
    };

    const handleDeleteCostInvoice = () => {
        if (props.invoice?.costInvoice?.id) {
            deleteCostInvoice();
        }
    };

    const handleInvoiceOpen = () => {
        navigate(`/invoices/${invoice.id}`);
    };

    const status = getCostStatus(costInvoice);

    return (
        <WhiteDocumentArea
            id="cost-document"
            style={{ marginTop: 15 }}
            disabled={returnLoading || props.sendingLoading}
        >
            {returnLoading || props.sendingLoading ? (
                <LoadingSpinner
                    size="50px"
                    color={COLOR_BLUM}
                    style={{
                        marginTop: '-25px',
                        position: 'absolute',
                        top: '50%',
                        zIndex: 9999999999,
                    }}
                />
            ) : null}

            {/* Show warning that salary has already been paid */}
            {salaryAlreadyPaid && isCostInvoiceStatusEditable(invoice.costInvoice?.status) && (
                <ValidationMessageBlock
                    type="warning"
                    style={{ marginBottom: 100 }}
                    title={`${t('costs.warning.salary-already-paid-return')} ${t('costs.warning.salary-already-paid-return-2')}`}
                />
            )}
            <Flex center fullWidth useWrap>
                <div style={{ marginRight: 'auto', flexGrow: 1 }}>
                    {status && (
                        <TitleCapsBold
                            color={status.color}
                            style={{
                                marginTop: 15,
                                minHeight: 45,
                            }}
                        >
                            {status.text}
                        </TitleCapsBold>
                    )}
                </div>

                <div>
                    {props.editButtonOnInvoicePreview &&
                        isCostInvoiceReSendable(props.invoice) &&
                        !shouldCostInvoiceBeReturnedBeforeEditing(props.invoice) && (
                            <EezyButton
                                color="purple"
                                onClick={handleInvoiceOpen}
                                disabled={salaryAlreadyPaid}
                            >
                                {t('general.edit')}
                            </EezyButton>
                        )}
                    {props.onCostInvoiceSend &&
                        isCostInvoiceReSendable(props.invoice) &&
                        costInvoiceHasCosts(props.invoice?.costInvoice) && (
                            <EezyButton
                                color="purple"
                                dark
                                disabled={props.sendingLoading}
                                onClick={props.onCostInvoiceSend}
                            >
                                {t('invoice.send')}
                            </EezyButton>
                        )}

                    {isAnkkaId(props.invoice!.costInvoice!.id) &&
                        (!costInvoiceHasCosts(props.invoice?.costInvoice) ||
                        ((salaryAlreadyPaid || invoice?.status === 'credited') &&
                            isCostInvoiceStatusEditable(invoice.costInvoice?.status)) ? (
                            <EezyButton color="purple" onClick={handleDeleteCostInvoice}>
                                {deletingCostInvoice ? <LoadingSpinner size="1em" /> : t('form.delete')}
                            </EezyButton>
                        ) : null)}

                    {shouldCostInvoiceBeReturnedBeforeEditing(props.invoice) && (
                        <EezyButton
                            color="purple"
                            dark
                            onClick={handleCostInvoiceReturn}
                            disabled={salaryAlreadyPaid || returnLoading}
                        >
                            {t('general.edit')}
                        </EezyButton>
                    )}
                </div>
            </Flex>
            <Flex>
                <LabelCapsSmall color="black" style={{ flexGrow: 1, fontSize: 13, marginBottom: 45 }}>
                    <Icon icon={['fal', 'coins']} color="black" /> {t('costs.breakdown')}
                </LabelCapsSmall>
            </Flex>
            {costInvoice.turnBackReason && (
                <div style={{ marginBottom: 45 }}>
                    <LabelCapsSmall>{t('costs.comments')}</LabelCapsSmall>
                    {costInvoice.turnBackReason.map((message: string, i) => {
                        return (
                            <PSmall
                                key={`message-${i}`}
                                style={
                                    i === 0
                                        ? {
                                              borderLeft: `3px solid ${COLOR_STATUS_WAITING}`,
                                              paddingLeft: '10px',
                                          }
                                        : {
                                              borderLeft: `3px solid ${COLOR_GREY_FOG}`,
                                              paddingLeft: '10px',
                                          }
                                }
                            >
                                {message}
                            </PSmall>
                        );
                    })}
                </div>
            )}
            {(isMessageEditable || costInvoice.messageToEezy) && (
                <FormSection style={{ marginBottom: 60 }}>
                    <FormTextArea
                        color="black"
                        readonly={!isMessageEditable}
                        label={t('costs.message-to-eezy') || ''}
                        labelStyle={{
                            opacity: 1,
                            textTransform: 'uppercase',
                        }}
                        name="messageToEezy"
                        onChange={(val) => handleUpdateMessage(val)}
                        placeholder={t('costs.message-to-eezy-ph') || ''}
                        value={costInvoice.messageToEezy || ''}
                    />
                </FormSection>
            )}
            {!editableCostInvoice && <CostDocumentSummary costInvoice={costInvoice} />}
            {/* Rejected costs and travels */}
            {countCostStatuses(costInvoice, 'rejected') > 0 && (
                <CostInvoiceTable>
                    <thead>
                        <tr>
                            <th colSpan={2}>{t('costs.rejected-costs')}</th>
                            <th>
                                {t('form.eurs', {
                                    eurs: formatCents(getCostInvoiceRejectedTotal(costInvoice), true),
                                })}
                            </th>
                            <th style={editableCostInvoice ? { width: 95 } : {}} />
                        </tr>
                    </thead>
                    <tbody>
                        {/* travels */}
                        {filterTravelsByStatus(costInvoice.travels, 'rejected')
                            .slice()
                            .sort(
                                (a: ITravel, b: ITravel) =>
                                    new Date(b.startTime || '').getTime() -
                                    new Date(a.startTime || '').getTime(),
                            )
                            .map((travel: ITravel) => {
                                return (
                                    <CostDocTravelRow
                                        key={`travel-rejected-${travel.id}`}
                                        costInvoiceId={costInvoice.id}
                                        editable={editableCostInvoice}
                                        invoiceId={invoice.id}
                                        isGroupInvoice={groupInvoiceCoworker}
                                        showOnlyRejected
                                        status={costInvoice.status}
                                        travel={travel}
                                    />
                                );
                            })}

                        {/* costs */}
                        {costInvoice.receiptCosts
                            .slice()
                            .filter((c: IReceiptCost) => c.status === 'rejected')
                            .sort(
                                (a: IReceiptCost, b: IReceiptCost) =>
                                    new Date(b.purchaseDate || '').getTime() -
                                    new Date(a.purchaseDate || '').getTime(),
                            )
                            .map((cost: IReceiptCost) => {
                                return (
                                    <CostDocCostRow
                                        key={`cost-rejected-${cost.id}`}
                                        cost={cost}
                                        costInvoiceId={costInvoice.id}
                                        editable={editableCostInvoice}
                                        invoiceId={invoice.id}
                                        isGroupInvoice={groupInvoiceCoworker}
                                        showOnlyRejected
                                    />
                                );
                            })}
                    </tbody>
                </CostInvoiceTable>
            )}
            {/* Travel expenses */}
            {(costInvoice.travels.length > 0 || editableCostInvoice) && (
                <CostInvoiceTable
                    onMouseOver={() => {
                        if (editableCostInvoice) {
                            client.query({
                                query: GET_ALLOWANCES,
                            });
                        }
                    }}
                >
                    <thead>
                        <tr>
                            <th colSpan={2}>{t('costs.travel-expenses')}</th>
                            <th>
                                {t('form.eurs', {
                                    eurs: formatCents(getTravelsTotal(costInvoice.travels), true),
                                })}
                            </th>
                            <th style={editableCostInvoice ? { width: 95 } : {}} />
                        </tr>
                    </thead>
                    <tbody>
                        {/* no sorting when editing, as we want newest to be the last one
                         for the copy to work */}
                        {costInvoice.travels
                            .slice()
                            .sort((a: ITravel, b: ITravel) =>
                                editable
                                    ? 0
                                    : new Date(b.startTime || '').getTime() -
                                      new Date(a.startTime || '').getTime(),
                            )
                            .map((travel: ITravel) => {
                                return (
                                    <CostDocTravelRow
                                        key={`travel-${travel.id}`}
                                        costInvoiceId={costInvoice.id}
                                        editable={editableCostInvoice}
                                        invoiceId={invoice.id}
                                        isGroupInvoice={groupInvoiceCoworker}
                                        status={costInvoice.status}
                                        travel={travel}
                                    />
                                );
                            })}
                    </tbody>
                </CostInvoiceTable>
            )}
            {editableCostInvoice && costInvoice.travels.length === 0 && (
                <P color="black">{t('costs.travel-help')}</P>
            )}
            {editableCostInvoice && (
                <Flex useWrap style={{ margin: '10px 0' }}>
                    <EezyRouterLink
                        color="black"
                        dark
                        hasIcon
                        square
                        style={{ marginRight: 5, marginTop: 5 }}
                        to={`${invoiceBaseUrl}/${invoice.id}/expenses/new`}
                        state={{ step: TRAVEL_CAR_ROUND }}
                        width={140}
                    >
                        <Icon color="white" icon={['far', 'plus']} />
                        {t('costs.travel-by-car-short')}
                    </EezyRouterLink>
                    <EezyRouterLink
                        color="black"
                        dark
                        hasIcon
                        square
                        style={{ margin: '5px 5px 0 0' }}
                        to={`${invoiceBaseUrl}/${invoice.id}/expenses/new`}
                        state={{ step: TRAVEL_OTHER_ROUND }}
                        width={140}
                    >
                        <Icon color="white" icon={['far', 'plus']} />
                        {t('costs.travel-other')}
                    </EezyRouterLink>
                    <EezyButton
                        color="black"
                        dark
                        disabled={costInvoice.travels.length === 0}
                        hasIcon
                        onClick={handleCopyTravel}
                        square
                        style={{ marginLeft: 0, marginTop: 5 }}
                        width={140}
                    >
                        {createTravelLoading ? (
                            <LoadingSpinner size="1em" />
                        ) : (
                            <>
                                <Icon color="white" icon={['far', 'plus']} />
                                {t('invoice.tableColumns.copy-last')}
                            </>
                        )}
                    </EezyButton>
                </Flex>
            )}
            {/* Cost expenses */}
            {(costInvoice.receiptCosts.length > 0 || editableCostInvoice) && (
                <CostInvoiceTable>
                    <thead>
                        <tr>
                            <th colSpan={2}>{t('costs.costs')}</th>
                            <th>
                                {t('form.eurs', {
                                    eurs: formatCents(getCostsTotalWithVat(costInvoice.receiptCosts), true),
                                })}
                            </th>
                            <th style={editableCostInvoice ? { width: 97 } : {}} />
                        </tr>
                    </thead>
                    <tbody>
                        {costInvoice.receiptCosts
                            .slice()
                            .sort(
                                (a: IReceiptCost, b: IReceiptCost) =>
                                    new Date(b.purchaseDate || '').getTime() -
                                    new Date(a.purchaseDate || '').getTime(),
                            )
                            .map((cost: IReceiptCost) => {
                                return (
                                    <CostDocCostRow
                                        key={`cost-${cost.id}`}
                                        cost={cost}
                                        costInvoiceId={costInvoice.id}
                                        editable={editableCostInvoice}
                                        invoiceId={invoice.id}
                                        isGroupInvoice={groupInvoiceCoworker}
                                    />
                                );
                            })}
                    </tbody>
                </CostInvoiceTable>
            )}
            {editableCostInvoice && costInvoice.receiptCosts.length === 0 && (
                <P color="black">{t('costs.cost-help')}</P>
            )}
            {editableCostInvoice && (
                <Flex useWrap style={{ margin: '15px 0' }}>
                    <EezyRouterLink
                        color="black"
                        dark
                        hasIcon
                        square
                        to={`${invoiceBaseUrl}/${invoice.id}/expenses/new`}
                        state={{ step: MATERIAL }}
                        width={140}
                    >
                        <Icon color="white" icon={['far', 'plus']} />
                        {t('costs.material')}
                    </EezyRouterLink>
                </Flex>
            )}
            {editableCostInvoice && <CostDocumentSummary costInvoice={costInvoice} />}
        </WhiteDocumentArea>
    );
};

export default CostDocument;
