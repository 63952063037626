import { useTranslation } from 'react-i18next';
import type { IBriefInvoice, IInvoice } from '../../../../shared/src/types/invoice';
import { EezyButton } from '../../components/Buttons';
import { HelpLabel } from '../../components/form';
import { P, RowTitle } from '../../components/textElements';
import { COLOR_BLACKWATER, COLOR_LILA_MEDIUM } from '../../styles/variables';
import { showCollectionInfoBox } from '../../utils/invoice/invoiceLogic';
import ColorBox from '../../components/ColorBox';
import LoadingSpinner from '../../components/Loading';

interface IProps {
    briefInvoice?: IBriefInvoice;
    invoice?: IInvoice;
    loading: boolean;
    onClickRequestDebtCollection: () => void;
}

export const CollectionInfo = (props: IProps) => {
    const { t } = useTranslation();

    if (!props.invoice || !showCollectionInfoBox(props.invoice, props.briefInvoice)) {
        return null;
    }

    /*
        If debt collection has been requested through phone or email,
        collectionRequested is null, but briefInvoice has inDebtCollection: true.
    */
    const inDebtCollection = props.invoice.collectionRequested || props.briefInvoice?.inDebtCollection;

    return (
        <ColorBox color={COLOR_LILA_MEDIUM} disableClose style={{ marginBottom: 30, padding: '45px 30px' }}>
            <RowTitle color={COLOR_BLACKWATER}>
                {t(`invoice.collection.${inDebtCollection ? 'on' : 'off'}.title`)}
            </RowTitle>
            {inDebtCollection ? (
                <P color="white">{t('invoice.collection.on.1')}</P>
            ) : (
                <>
                    <P color="white">{t('invoice.collection.off.1')}</P>
                    <P color="white">{t('invoice.collection.off.2')}</P>
                    <P color="white">{t('invoice.collection.off.3')}</P>
                </>
            )}

            <div style={{ marginTop: 30 }}>
                <HelpLabel
                    dark={true}
                    helpTexts={[
                        'invoice.debtCollection.help1',
                        'invoice.debtCollection.help2',
                        'invoice.debtCollection.help3',
                        'invoice.debtCollection.help4',
                        'invoice.debtCollection.help5',
                        'invoice.debtCollection.help6',
                        'invoice.debtCollection.help7',
                        'invoice.debtCollection.help8',
                        'invoice.debtCollection.help9',
                        'invoice.debtCollection.help10',
                    ]}
                    labelComponent={
                        <EezyButton
                            color="purple"
                            disabled={inDebtCollection || props.loading}
                            onClick={props.onClickRequestDebtCollection}
                            transparent
                        >
                            {props.loading ? (
                                <LoadingSpinner size="1em" />
                            ) : (
                                t(`invoice.collection.${inDebtCollection ? 'on' : 'off'}.button`)
                            )}
                        </EezyButton>
                    }
                />
            </div>
        </ColorBox>
    );
};
