import { gql } from '@apollo/client';

const GET_INVOICE_VAT_OPTIONS = gql`
    query GetInvoiceVatOptions {
        invoiceVatOptions {
            value
            default
            templates
        }
    }
`;

export default GET_INVOICE_VAT_OPTIONS;
