import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import type { IAmountWithVat, IReceiptCost } from '../../../../shared/src/types/costs';
import { EezyButton } from 'components/Buttons';
import {
    DatePicker,
    FormInput,
    FormNumberInput,
    FormRow,
    FormSection,
    FormSelect,
    SplitRow,
} from 'components/form';
import { FormLabel } from 'components/form/FormLabel';
import { Icon } from 'components/Icon';
import { P } from 'components/textElements';
import { COLOR_GREYHOUND } from 'styles/variables';
import { formatCents, formatDateISO } from 'utils';
import {
    getReceipt,
    getTravelReceiptType,
    type ICostComponentProps,
    isSupplyReceipt,
    RECEIPT,
} from 'utils/costs/costLogic';
import GET_COST_EXPENSE_VAT_OPTIONS from 'containers/options-config/queries/getCostExpenseVatOptions';
import { useLazyQuery } from '@apollo/client';
import { useEffect, useState, useMemo } from 'react';
import type { VatOption } from 'types/invoice.type';

interface IReceiptPayload {
    amountsWithVats?: IAmountWithVat[];
    purchaseDate?: string;
    description?: string;
}

export const ReceiptDetails = (props: ICostComponentProps) => {
    const [costExpenseVatOptions, setCostExpenseVatOptions] = useState<VatOption[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [rowIds, setRowIds] = useState<string[]>([]);

    const { t } = useTranslation();
    const { dispatch } = props;

    if (!props.invoice) {
        return null;
    }

    const { isGroupInvoice, template, totalWithVat, total } = props.invoice;
    const withoutVAT = !isGroupInvoice && template === 'domestic' && totalWithVat === total;
    const receipt: IReceiptCost | undefined = getReceipt(props.currentCost, props.travelCostTemp);

    const [fetchCostExpenseVatOptions] = useLazyQuery(GET_COST_EXPENSE_VAT_OPTIONS, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            if (data?.costExpenseVatOptions) {
                setCostExpenseVatOptions(data.costExpenseVatOptions);
                setIsLoading(false);
            }
        },
    });

    useEffect(() => {
        if (costExpenseVatOptions.length === 0) {
            fetchCostExpenseVatOptions();
        }
    }, [costExpenseVatOptions, fetchCostExpenseVatOptions]);

    useEffect(() => {
        const amounts = receipt?.amountsWithVats ?? [];
        const amountsLength = amounts.length;

        setRowIds((prev) => {
            const newIds = [...prev];
            while (newIds.length < amountsLength) {
                newIds.push(crypto.randomUUID());
            }
            newIds.length = amountsLength;
            return newIds;
        });
    }, [receipt?.amountsWithVats]);

    // Keep this memoized to prevent FormSelect from resetting its hover state
    const vatSelectOptions = useMemo(() => {
        if (costExpenseVatOptions.length === 0) {
            return [];
        }

        const usedVats = withoutVAT
            ? costExpenseVatOptions.filter((i) => i.value !== 0).map((i) => i.value)
            : (receipt?.amountsWithVats ?? []).map((r) =>
                  typeof r.vatPercent === 'number' ? r.vatPercent : Number.parseFloat(r.vatPercent),
              );

        return costExpenseVatOptions.map((i) => ({
            disabled: usedVats.includes(i.value),
            label: t('form.percent', { percentage: i.value.toString() }),
            value: i.value.toString(),
        }));
    }, [costExpenseVatOptions, receipt?.amountsWithVats, withoutVAT, t]);

    if (!receipt || isLoading || costExpenseVatOptions.length === 0) {
        return null;
    }

    const isTemp = () => {
        return !!props.travelCostTemp;
    };

    const saveReceiptCost = (payload: IReceiptPayload) => {
        if (isTemp()) {
            dispatch({
                payload,
                type: 'SAVE_TRAVEL_COST_TEMP',
            });
        } else {
            dispatch({ payload, type: 'SAVE_COST' });
        }
    };

    const handleAddRow = () => {
        const availableVat = vatSelectOptions.find((v) => !v.disabled);
        if (!availableVat) return;

        const amountsWithVats = [
            ...(receipt.amountsWithVats ?? []),
            { vatPercent: Number.parseFloat(availableVat.value), amount: 0 },
        ];
        saveReceiptCost({ amountsWithVats });
    };

    const handleDeleteRow = (index: number) => {
        const currentArray = receipt?.amountsWithVats ?? [];
        const amountsWithVats = currentArray.filter((_r, i) => i !== index);
        saveReceiptCost({ amountsWithVats });
    };

    const handleUpdateRow = (updates: Partial<IAmountWithVat>, index: number) => {
        const currentArray = receipt?.amountsWithVats ?? [];
        const amountsWithVats = currentArray.map((item, i) => {
            if (i === index) {
                return { ...item, ...updates };
            }
            return item;
        });
        saveReceiptCost({ amountsWithVats });
    };

    return (
        <div>
            <FormSection>
                <SplitRow>
                    {((!isSupplyReceipt(receipt) && !isTemp()) ||
                        (receipt.description && !getTravelReceiptType(receipt) && isTemp())) && (
                        <FormInput
                            name="cost-title"
                            label={t('costs.receipt-label') || ''}
                            placeholder={
                                props.step === RECEIPT
                                    ? t('costs.receipt-placeholder-travel') || ''
                                    : t('costs.receipt-placeholder-cost') || ''
                            }
                            required
                            onChange={(description) => saveReceiptCost({ description })}
                            style={{ flexGrow: 2 }}
                            value={receipt.description}
                        />
                    )}
                    <FormRow>
                        <FormLabel htmlFor="cost-date" value={receipt.purchaseDate}>
                            {props.step === RECEIPT ? t('costs.buy-date-travel') : t('costs.buy-date')}
                        </FormLabel>
                        <DatePicker
                            disableToolbar
                            label={props.step === RECEIPT ? t('costs.buy-date-travel') : t('costs.buy-date')}
                            icon={['far', 'calendar-day']}
                            id="cost-date"
                            onChange={(d: Date | null) => {
                                if (d) {
                                    saveReceiptCost({
                                        purchaseDate: formatDateISO(d),
                                    });
                                }
                            }}
                            placeholder=""
                            required
                            value={receipt.purchaseDate}
                        />
                    </FormRow>
                </SplitRow>

                {(receipt.amountsWithVats ?? []).map((row, i) => (
                    <SplitRow style={{ alignItems: 'flex-end' }} key={rowIds[i]}>
                        <FormRow>
                            <FormLabel id={`cost-total-${i}`} value={row.amount}>
                                {t('costs.total-with-vat')}
                            </FormLabel>
                            <div
                                style={{
                                    alignItems: 'baseline',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginRight: 5,
                                }}
                            >
                                <FormNumberInput
                                    isEur
                                    endAdornment="€"
                                    name={`cost-total-${i}`}
                                    onChange={(val) => {
                                        const numVal = typeof val === 'string' ? Number(val) : val;
                                        handleUpdateRow({ amount: numVal }, i);
                                    }}
                                    required
                                    value={row.amount ?? 0}
                                />
                            </div>
                        </FormRow>
                        <FormSelect
                            name={`cost-alv-${i}`}
                            label={t('costs.vat') || ''}
                            aria-label={t('costs.vat')}
                            options={vatSelectOptions}
                            onChange={(vatStr) => {
                                const parsedVat = Number.parseFloat(vatStr);
                                handleUpdateRow({ vatPercent: parsedVat }, i);
                            }}
                            required
                            showIcon
                            value={row.vatPercent?.toString() ?? '0'}
                        />
                        <EezyButton
                            aria-label={
                                t('costs.vat-remove', {
                                    total: formatCents(row.amount),
                                    vat: row.vatPercent,
                                }) || ''
                            }
                            color="purple"
                            disabled={(receipt.amountsWithVats ?? []).length === 1}
                            onClick={() => handleDeleteRow(i)}
                            square
                            style={{ marginLeft: 5, padding: 0 }}
                            width={30}
                        >
                            <Icon icon={['far', 'trash-alt']} color="black" />
                        </EezyButton>
                        <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                            {i === (receipt.amountsWithVats ?? []).length - 1 ? (
                                <EezyButton
                                    color="purple"
                                    disabled={(receipt.amountsWithVats ?? []).length >= 4}
                                    square
                                    onClick={handleAddRow}
                                    width={110}
                                >
                                    {t('costs.add-vat')}
                                </EezyButton>
                            ) : (
                                <span style={{ minWidth: 115 }} />
                            )}
                        </Box>
                    </SplitRow>
                ))}

                <Box sx={{ display: { sm: 'none', xs: 'block' } }}>
                    <FormSection>
                        <EezyButton
                            color="black"
                            disabled={(receipt.amountsWithVats ?? []).length >= 4}
                            onClick={handleAddRow}
                            square
                            style={{
                                alignSelf: 'flex-end',
                                width: '120px',
                                padding: '8px 16px',
                            }}
                        >
                            {t('costs.add-vat')}
                        </EezyButton>
                    </FormSection>
                </Box>

                <P color={COLOR_GREYHOUND} style={{ marginTop: 30 }}>
                    {t(withoutVAT ? 'costs.receipt-note-no-VAT' : 'costs.receipt-note-1')}
                </P>
                <P color={COLOR_GREYHOUND} style={{ marginTop: 15 }}>
                    {t('costs.receipt-note-2')}
                </P>
            </FormSection>
        </div>
    );
};
